<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.booking.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. Kontrak</label>
        <vs-input class="w-full" :value="initData.booking.no_kontrak" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl Batal</label>
        <vs-input class="w-full" :value="initData.booking.tgl_batal" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Dibatalkan Oleh</label>
        <vs-input class="w-full" :value="initData.booking.username_batal" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Judul</label>
        <vs-input class="w-full" :value="initData.booking.alasan_batal" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-input class="w-full" :value="initData.booking.keterangan_batal" readonly/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabDetail',
  props: ['isActive', 'initData'],
  components: {

  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  }
}
</script>
